<template>
  <div>
    <!-- Send SMS -->
    <form v-if="notificationType === 'sms'">
      <vs-row>
        <vs-col vs-w="6">
          <vs-select
            v-model="smsCodeModel"
            class="mb-3"
            label="Mensagem"
            width="400px"
          >
            <vs-select-item
              v-for="item, index in smsTemplates"
              :key="index"
              :text="item.messageDescription"
              :value="item.templateCode"
            />
          </vs-select>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col :vs-w="sendSingle ? '12' : '6'">
          <vs-textarea
            v-model="smsTextModel"
            class="w-100 pt-2 mb-3"
            color="primary"
            counter="400"
            :counter-danger.sync="smsTextDanger"
            :disabled="smsCode !== null"
            height="100px"
            :placeholder="getTextPlaceholder('smsCode')"
          />
        </vs-col>
      </vs-row>

      <vs-button
        v-if="sendSingle"
        :disabled="submitDisabled"
        icon="send"
        size="large"
        @click="sendSingleSMS"
      >
        Enviar
      </vs-button>
    </form>

    <!-- Send WhatsApp -->
    <form v-if="notificationType === 'whatsapp'">
      <vs-row>
        <vs-col vs-w="6">
          <vs-select
            v-model="whatsCodeModel"
            class="mb-3"
            label="Mensagem"
            width="400px"
          >
            <vs-select-item
              v-for="item, index in whatsAppTemplates"
              :key="index"
              :text="item.messageDescription"
              :value="item.templateCode"
            />
          </vs-select>
        </vs-col>
      </vs-row>

      <vs-button
        v-if="sendSingle"
        :disabled="submitDisabled"
        icon="send"
        size="large"
        @click="sendSingleWhatsApp"
      >
        Enviar
      </vs-button>
    </form>
  </div>
</template>

<script>
/* Services */
import Campaign from '@/services/campaign';

const campaignService = new Campaign();

export default {
  name: 'MessageForm',
  props: {
    notificationType: {
      type: String,
      required: true,
    },
    smsCode: {
      type: String,
      default: null,
    },
    smsText: {
      type: String,
      default: '',
    },
    whatsCode: {
      type: String,
      default: null,
    },
    sendSingle: {
      type: Boolean,
      default: () => false,
    },
    submitDisabled: {
      type: Boolean,
      default: () => false,
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    msgTemplates: [],
    smsTextDanger: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    systemId() {
      return this.$store.state.sysID;
    },
    idNumberType() {
      return this.sendSingle ? 0 : 1;
    },
    smsTemplates() {
      if (this.msgTemplates.length === 0) {
        return [];
      } else {
        let filtered = [...this.msgTemplates];

        filtered = filtered.filter((template) => {
          return template.contents.some((c) => {
            return c.type.toLowerCase() === 'sms';
          });
        });

        filtered.unshift({
          messageDescription: 'Texto livre',
          templateCode: null,
        });

        return filtered;
      }
    },
    whatsAppTemplates() {
      if (this.msgTemplates.length === 0) {
        return [];
      } else {
        let filtered = [...this.msgTemplates];

        filtered = filtered.filter((template) => {
          return template.contents.some((c) => {
            return c.type.toLowerCase() === 'whatsapp';
          });
        });

        return filtered;
      }
    },
    smsCodeModel: {
      get() { return this.smsCode },
      set(value) { this.$emit('set-sms-code', value) },
    },
    smsTextModel: {
      get() { return this.smsText },
      set(value) { this.$emit('set-sms-text', value) },
    },
    whatsCodeModel: {
      get() { return this.whatsCode },
      set(value) { this.$emit('set-whats-code', value) },
    },
    whatsTextModel: {
      get() { return this.whatsText },
      set(value) { this.$emit('set-whats-text', value) },
    },
  },
  watch: {
    smsCode(value) {
      if (value && this.notificationType === 'sms') {
        const disabled = this.smsCode === null ?
          (value.length === 0 || this.smsTextDanger) :
          false;

        this.setSubmitDisabled(disabled);
      }
    },
    smsText(value) {
      if (this.notificationType === 'sms') {
        const disabled = this.smsCode === null ?
          (value.length === 0 || this.smsTextDanger) :
          false;

        this.setSubmitDisabled(disabled);
      }
    },
    whatsCode(value) {
      if (value && this.notificationType === 'whatsapp') {
        const disabled = this.whatsCode === null ?
          (value.length === 0 || this.whatsTextDanger) :
          false;

        this.setSubmitDisabled(disabled);
      }
    },
    whatsText(value) {
      if (this.notificationType === 'whatsapp') {
        const disabled = this.whatsCode === null ?
          (value.length === 0 || this.whatsTextDanger) :
          false;

        this.setSubmitDisabled(disabled);
      }
    },
  },
  created() {
    this.getMessageTemplates();
  },
  methods: {
    /* API */
    getMessageTemplates() {
      campaignService.getMessageTemplates(this.ownerId)
        .then((res) => this.msgTemplates = res);
    },
    sendSingleSMS() {
      this.$store.dispatch('set_isLoadingActive', true);

      const payload = {
        smsText: this.smsText,
        templateCode: this.smsCode,
        number: String(this.payload.identity),
        numberType: this.idNumberType,
        params: {},
        processRef: '',
        processType: 0,
        ownerId: this.ownerId,
        systemId: this.systemId,
      };

      campaignService.sendSingleSMS(payload)
        .then(() => this.smsSuccessAlert())
        .catch((err) => this.smsFailAlert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.closeParentPopup();
        });
    },
    sendSingleWhatsApp() {
      this.$store.dispatch('set_isLoadingActive', true);

      const payload = {
        body: this.whatsText,
        messageCode: this.whatsCode,
        number: String(this.payload.identity),
        numberType: this.idNumberType,
        params: {},
        processRef: '',
        processType: 0,
        ownerId: this.ownerId,
        systemId: this.systemId,
      };

      campaignService.sendSingleWhatsApp(payload)
        .then(() => this.whatSuccessAlert())
        .catch((err) => this.whatsFailAlert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.closeParentPopup();
        });
    },
    /* Helpers */
    getTextPlaceholder(prop) {
      return this[prop] !== null
        ? 'Mensagem gerada automaticamente.'
        : 'Escreva sua mensagem aqui...';
    },
    setSubmitDisabled(value) {
      this.$emit('set-submit-disabled', value);
    },
    /* Alerts */
    smsSuccessAlert() {
      this.$vs.notify({
        time: 4000,
        color: 'primary',
        title: 'SMS Enviado',
      });
    },
    smsFailAlert(err) {
      this.$vs.notify({
        color: 'danger',
        title: 'SMS Não Enviado',
        text: err,
      });
    },
    whatSuccessAlert() {
      this.$vs.notify({
        time: 4000,
        color: 'primary',
        title: 'WhatsApp Enviado',
      });
    },
    whatsFailAlert(err) {
      this.$vs.notify({
        color: 'danger',
        title: 'WhatsApp Não Enviado',
        text: err,
      });
    },
    /* Popups */
    closeParentPopup() {
      this.$emit('close-parent-popup');
    },
  },
};
</script>
