<template>
  <div>
    <!-- Main -->
    <div v-if="sendSingle">
      <form>
        <vs-row>
          <!-- Email Subject -->
          <vs-col
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-input
              v-model="emailSubjectModel"
              class="w-100"
              icon-after="true"
              icon="mode_edit"
              label="Assunto"
            />
          </vs-col>

          <!-- Email Body -->
          <vs-col
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-textarea
              v-model="emailBodyModel"
              class="w-100 pt-2 mb-3 mt-3"
              color="primary"
              :disabled="selectedTempId !== 0"
              counter="400"
              height="100px"
              :placeholder="getBodyPlaceholder()"
            />
          </vs-col>

          <!-- Selected Template -->
          <vs-col
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <div class="select-template-wrapper mt-1">
              <div
                v-if="isSelected"
                class="template-card"
              >
                <div class="template-card--preview">
                  <div class="prvw--title-wrapper">
                    <div class="prvw--title">
                      <h3>{{ selectedTempName }}</h3>
                    </div>
                  </div>

                  <div class="prvw--image">
                    <img
                      alt="Preview indisponível"
                      :src="selectedTempUrl"
                    >
                  </div>
                </div>

                <div class="template-card--controls">
                  <vs-button
                    text-color="black"
                    type="flat"
                    icon="expand"
                    size="small"
                    @click="openExpandPopup"
                  />
                </div>
              </div>

              <vs-button
                class="w-100"
                @click="openSelectPopup"
              >
                <i class="fa fa-envelope mr-1" />

                Selecionar Template
              </vs-button>

              <vs-button
                v-show="isSelected"
                class="clear-template-btn"
                :color="colorPrimary"
                icon="close"
                size="small"
                radius
                type="flat"
                @click="clearSelTemplate"
              />
            </div>
          </vs-col>
        </vs-row>

        <vs-button
          :disabled="sendSingleDisabled"
          icon="send"
          size="large"
          class="mt-4"
          @click="sendSingleEmail"
        >
          Enviar
        </vs-button>
      </form>
    </div>
    <div
      v-else
      class="email-form-wrapper"
    >
      <form>
        <vs-row>
          <!-- Email Subject -->
          <vs-col
            vs-lg="5"
            vs-sm="12"
            vs-xs="12"
          >
            <label class="title small font-weight-bold">
              Assunto do E-mail:
            </label>

            <vs-input
              v-model="emailSubjectModel"
              class="w-100"
              icon-after="true"
              icon="mode_edit"
              placeholder="Assunto do E-mail"
            />
          </vs-col>

          <!-- Sender -->
          <vs-col
            vs-lg="5"
            vs-sm="12"
            vs-xs="12"
          >
            <label class="title small font-weight-bold">
              E-mail remetente:
            </label>

            <vs-select
              id="email--select-sender"
              v-model="selectedSenderModel"
              class="w-100"
              description-text="Campo obrigatório*"
            >
              <div
                v-for="(sender, index) in senders"
                :key="index"
                class="d-flex align-items-center justify-content-between"
              >
                <vs-select-item
                  :text="sender.name"
                  :value="sender.email"
                  class="w-100"
                />

                <small
                  class="position-absolute"
                  :style="{ zIndex: '-1', right: '1rem', userSelect: 'none' }"
                >
                  {{ sender.email }}
                </small>
              </div>
            </vs-select>
          </vs-col>
        </vs-row>
      </form>

      <!-- Selecte Template -->
      <div class="select-template-wrapper">
        <div
          v-if="isSelected"
          class="template-card"
        >
          <div class="template-card--preview">
            <div class="prvw--title-wrapper">
              <div class="prvw--title">
                <h3>{{ selectedTempName }}</h3>
              </div>
            </div>

            <div class="prvw--image">
              <img
                alt="Preview indisponível"
                :src="selectedTempUrl"
              >
            </div>
          </div>

          <div class="template-card--controls">
            <vs-button
              text-color="black"
              type="flat"
              icon="expand"
              size="small"
              @click="openExpandPopup"
            />
          </div>
        </div>

        <vs-button
          class="w-100"
          @click="openSelectPopup"
        >
          <i class="fa fa-envelope mr-1" />

          Selecionar Template
        </vs-button>

        <vs-button
          v-show="isSelected"
          class="clear-template-btn"
          :color="colorPrimary"
          icon="close"
          size="small"
          radius
          type="flat"
          @click="clearSelTemplate"
        />
      </div>
    </div>

    <!-- Popups -->
    <!-- Select Template Popup -->
    <vs-popup
      title="Selecionar Template"
      :active.sync="selectPopup"
    >
      <div class="template-popup">
        <div class="template-list">
          <div
            v-for="template in customTemplates"
            :key="template.id"
            class="template-card"
          >
            <div class="template-card--preview">
              <div class="prvw--title-wrapper">
                <div class="prvw--title">
                  <h3>{{ template.value }}</h3>
                </div>
              </div>

              <div class="prvw--image">
                <img
                  :src="template.urlPreview"
                  alt="Preview indisponível"
                >
              </div>
            </div>

            <div class="template-card--controls">
              <vs-button
                text-color="rgb(31, 41, 55)"
                type="flat"
                icon="check"
                size="small"
                @click="selectTemplate(template)"
              />
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- Go To Templates Prompt -->
    <vs-prompt
      color="primary"
      :active.sync="noTemplatesPrompt"
      cancel-text="Cancelar"
      accept-text="Sim"
      title="Nenhum template encontrado"
      @accept="goToTemplates"
    >
      <b>Você ainda não possui nenhum template. Deseja criar um?</b>
    </vs-prompt>

    <!-- Expand Preview Popup -->
    <vs-popup
      :title="selectedTempName"
      :active.sync="expandPopup"
    >
      <div class="expand-preview-popup">
        <img
          :src="selectedTempUrl"
          alt="Preview indisponível"
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Services */
import Campaign from '@/services/campaign';
import Template from '@/services/template';

const campaignService = new Campaign();
const templateService = new Template();

export default {
  name: 'EmailForm',
  props: {
    emailBody: {
      type: String,
      default: '',
    },
    emailSubject: {
      type: String,
      required: true,
    },
    selectedSender: {
      type: String,
      default: '',
    },
    selectedTempId: {
      type: Number,
      default: 0,
    },
    selectedTempName: {
      type: String,
      default: '',
    },
    selectedTempUrl: {
      type: String,
      default: '',
    },
    sendSingle: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    customTemplates: [],
    senders: [],
    /* Popups */
    expandPopup: false,
    noTemplatesPrompt: false,
    selectPopup: false,
  }),
  computed: {
    emailBodyModel: {
      get() { return this.emailBody },
      set(value) { this.$emit('set-email-body', value) },
    },
    emailSubjectModel: {
      get() { return this.emailSubject },
      set(value) { this.$emit('set-email-subject', value) },
    },
    selectedSenderModel: {
      get() { return this.selectedSender },
      set(value) { this.$emit('set-selected-sender', value) },
    },
    isSelected() {
      return this.selectedTempId !== 0;
    },
    sendSingleDisabled() {
      if (this.isSelected) {
        return this.emailSubject.length === 0;
      } else {
        return (this.emailBody.length === 0 || this.emailSubject.length === 0);
      }
    },
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    systemId() {
      return this.$store.state.sysID;
    },
    /* Style */
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
  },
  mounted() {
    this.getSenders();
    this.getTemplates();
  },
  methods: {
    /* API */
    getSenders() {
      this.$store.dispatch('set_isLoadingActive', true);

      campaignService.getCampaignConfig(this.ownerId)
        .then((res) => {
          if (res.senders && res.senders.length > 0) {
            this.senders = res.senders;
          }
        })
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getTemplates() {
      this.$store.dispatch('set_isLoadingActive', true);

      templateService.getTemplates(this.ownerId)
        .then((res) => this.customTemplates = res)
        .catch(() => this.noTemplatesPrompt = true)
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    sendSingleEmail() {
      this.$store.dispatch('set_isLoadingActive', true);

      const emailTemplateType = 1;

      const payload = {
        body: this.emailBody,
        destinatary: this.payload.email,
        subject: this.emailSubject,
        fileID: this.selectedTempId,
        templateUrl: this.selectedTempUrl,
        templateType: emailTemplateType,
        attachmentsId: [],
        ownerId: this.ownerId,
        systemId: this.systemId,
      };

      campaignService.sendSingleEmail(payload)
        .then(() => this.emailSuccessAlert())
        .catch((err) => this.emailFailAlert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.closeParentPopup();
        });
    },
    /* Helpers */
    clearSelTemplate() {
      this.$emit('set-selected-temp-id', 0);
      this.$emit('set-selected-temp-url', '');
      this.$emit('set-selected-temp-name', '');
      this.closeSelectPopup();
    },
    getBodyPlaceholder() {
      return this.selectedTempId !== 0
        ? 'Corpo do e-mail gerado automaticamente.'
        : 'Escreva o corpo do e-mail aqui...';
    },
    selectTemplate(template) {
      this.$emit('set-selected-temp-id', template.id);
      this.$emit('set-selected-temp-url', template.urlPreview);
      this.$emit('set-selected-temp-name', template.value);
      this.closeSelectPopup();
    },
    /* Alerts */
    emailSuccessAlert() {
      this.$vs.notify({
        time: 4000,
        color: 'primary',
        title: 'Email Enviado',
      });
    },
    emailFailAlert(err) {
      this.$vs.notify({
        color: 'danger',
        title: 'Email Não Enviado',
        text: err,
      });
    },
    /* Popups */
    closeParentPopup() {
      this.$emit('close-parent-popup');
    },
    closeSelectPopup() {
      this.selectPopup = false;
    },
    openExpandPopup() {
      this.expandPopup = true;
    },
    openSelectPopup() {
      this.selectPopup = true;
    },
    /* Router */
    goToTemplates() {
      this.$router.push('/templates');
    },
  },
};
</script>

<style scoped lang="scss">
.email-form-wrapper {
  margin-bottom: 1.85rem;
}

.template-popup {
  width: 100%;
  display: flex;
  justify-content: center;

  .template-list {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin-left: 0.5rem;
  }
}

.select-template-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  border: 0;
  border-radius: 0.3rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  margin: 1.25rem 0 0;
  max-width: 290px;
  position: relative;

  .clear-template-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.template-card {
  align-items: center;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 11.65rem;
  width: 7.65rem;

  .template-card--preview {
    border-radius: 4px 4px 0 0;
    position: relative;
    height: 100%;
    width: 100%;

    .prvw--title-wrapper {
      width: 100%;
      position: absolute;
      top: 0;

      .prvw--title {
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        padding: 0.45rem;
        width: 100%;

        h3 {
          color: white;
          font-size: 1em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          z-index: 99;
        }
      }

      .prvw--title::after {
        content: "";
        background: transparent;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(40, 40, 50, 0.9) 70%,
          rgba(0, 0, 0, 0.7) 100%
        );
        border-radius: 4px 4px 0 0;
        height: 42px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 98;
      }
    }

    .prvw--image {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

      img {
        align-items: center;
        display: flex;
        justify-content: center;
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .template-card--controls {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: stretch;

    /* Overwrite Vuesax's button style */
    .includeIconOnly {
      width: 100% !important;
    }
  }
}

.expand-preview-popup {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>

<style>
#email--select-sender .vs-select--item:focus, .vs-select--item:hover {
  background: #DEDEDE50;
}
</style>
